<template>
  <div class="industrialDesign">
    <!-- 页面一 -->
    <div class="industrialDesign GL_declareForm Gl_input">
      <Crumbs :title="'设计创新企业情况 '"></Crumbs>
      <Form
        class="form"
        ref="formValidate"
        :model="formValidate"
        :label-width="0"
      >
        <Row>
          <Col class="col_unit" span="24">单位：万元、个、%</Col>
        </Row>
        <!-- 企业名称 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业名称</Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.companyName"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业注册地址</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.companyAddress"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span class="redStar">*</span>税务登记区
            </Col>
            <Col class="m_col" span="8">
              <Select
                disabled
                v-model="formValidate.industrialDesignEnterprise.registeredArea"
              >
                <Option
                  v-for="item in areaList"
                  :key="item.dataId"
                  :value="item.dataId"
                  >{{ item.dataValue }}</Option
                >
              </Select>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业经营地址</Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.manageAddress"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!--  基本情况-->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">成立时间</Col>
            <Col class="m_col" span="8">
              <DatePicker
                readonly
                type="date"
                v-model="
                  formValidate.industrialDesignEnterprise.establishedTime
                "
                placeholder="选择时间"
              ></DatePicker>
            </Col>
            <Col class="m_col m_title" span="4">注册资本（万元）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="
                  formValidate.industrialDesignEnterprise.registeredCapital
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">资产总额（万元）</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.propertyTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">职工人数</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.employeeCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业注册类型</Col>
            <Col class="m_col" span="20">
              <div style="width:100%">
                <RadioGroup
                  class="RadioGroup"
                  size="large"
                  v-model="
                    formValidate.industrialDesignEnterprise
                      .unitRegistrationTypeParentId
                  "
                >
                  <Radio
                    disabled
                    :key="index"
                    v-for="(item, index) in formValidate.companyType"
                    :label="item.dictId"
                    >{{ item.dictName }}</Radio
                  >
                </RadioGroup>
                <br />
                <div
                  v-show="childrenList.length > 0"
                  style="border-top:1px dashed #ccc;margin:5px 0"
                ></div>
                <RadioGroup
                  v-show="childrenList.length > 0"
                  class="RadioGroup"
                  size="large"
                  v-model="
                    formValidate.industrialDesignEnterprise.propertyOfOwnership
                  "
                >
                  <Radio
                    disabled
                    :key="index2"
                    v-for="(item2, index2) in childrenList"
                    :label="item2.dataId"
                    >{{ item2.dataValue }}</Radio
                  >
                </RadioGroup>
              </div>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业是否属于</Col>
            <Col class="m_col m_padding" span="20">
              <CheckboxGroup size="large" v-model="CheckboxArray">
                <Checkbox disabled label="上市企业"></Checkbox>
                <Checkbox disabled label="高新技术企业"></Checkbox>
              </CheckboxGroup>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">主要服务领域</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="
                  formValidate.industrialDesignEnterprise.rangeOfServices
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">银行信用等级</Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.creditRating"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 联系方式 -->
        <Row>
          <Col span="24">联系方式</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">人员</Col>
            <Col class="m_col m_title" span="5">姓名</Col>
            <Col class="m_col m_title" span="5">职务</Col>
            <Col class="m_col m_title" span="5">联系手机</Col>
            <Col class="m_col m_title" span="5">电子邮箱</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业负责人</Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.principalName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.principalPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.principalPhone"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.principalEmail"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">申请联系人</Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.contactsName"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.contactsPost"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.contactsPhone"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.contactsEmail"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 人员构成 -->
        <Row>
          <Col span="24">人员构成</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">设计从业人数</Col>
            <Col class="m_col" span="20">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.employeesCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span>其中：本科及以上学历⼈员数</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.degreeOfCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span>其中：本科及以上学历⼈员数占比(%)</span>
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.degreeOf"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
              <span
                >具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数</span
              >
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="formValidate.industrialDesignEnterprise.specialtyCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col m_title" span="4">
              <span
                >具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数占⽐(%)</span
              >
            </Col>
            <Col class="m_col" span="8">
              <Input
                readonly
                v-model="
                  formValidate.industrialDesignEnterprise.specialtyProportion
                "
                class="unit_input"
              />
            </Col>
          </Row>
        </div>

        <!-- 经济指标 -->
        <Row>
          <Col span="24">经济指标</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">近三年主要指标</Col>
            <Col class="m_col m_title" span="6">2020年</Col>
            <Col class="m_col m_title" span="6">2021年</Col>
            <Col class="m_col m_title" span="6">2022年前三季度</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">公司营业收入（万元）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].companyIncome"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].companyIncome"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].companyIncome"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">
              其中：设计服务收入占比（%）
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].industrialDesignDuty"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].industrialDesignDuty"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].industrialDesignDuty"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">纳税总额（万元）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].profitAndTaxTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].profitAndTaxTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].profitAndTaxTotal"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">利润总额（万元）</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].profitTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].profitTotal"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].profitTotal"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 经济指标 -->
        <Row>
          <Col span="24">设计创新成果</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">近三年主要指标</Col>
            <Col class="m_col m_title" span="6">2020年</Col>
            <Col class="m_col m_title" span="6">2021年</Col>
            <Col class="m_col m_title" span="6">2022年前三季度</Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >设计项目完成数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].projectFinishCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].projectFinishCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].projectFinishCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >承担服务外包项目数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].epibolyProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].epibolyProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].epibolyProjectCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中:承担国外项目数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].foreignProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].foreignProjectCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].foreignProjectCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >专利授权数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].patentLicensingCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].patentLicensingCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].patentLicensingCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中:发明专利数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].inventPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].inventPatentCount"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].inventPatentCount"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">实用新型专利数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].practicalPatent"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].practicalPatent"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].practicalPatent"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">外观设计专利数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].appearancePatent"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].appearancePatent"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].appearancePatent"
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col
              style="justify-content: flex-start;"
              class="m_col m_title"
              span="6"
              >版权数</Col
            >
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.industrialEiList[0].copyrightAuthorization
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.industrialEiList[1].copyrightAuthorization
                "
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="
                  formValidate.industrialEiList[2].copyrightAuthorization
                "
                class="unit_input"
              />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">其中：软件著作权数</Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[0].softPatent"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[1].softPatent"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="formValidate.industrialEiList[2].softPatent"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <div class="resourcePerformance GL_declareForm Gl_input">
      <Crumbs :title="'资源及绩效 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateThree"
        :model="formValidateThree"
        :label-width="0"
      >
        <Row>
          <Col class="col_unit" span="24">单位：万元、%</Col>
        </Row>
        <!-- 设计成果获奖情况 -->
        <Row>
          <Col span="24">设计成果获奖情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">获奖作品</span>
            </Col>
            <Col class="m_col m_title" span="6">奖项名称</Col>
            <Col class="m_col m_title" span="6">获得时间</Col>
            <Col class="m_col m_title" span="6">授奖部门(或机构)</Col>
          </Row>
          <Row
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .awardsInfoList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input readonly v-model="item.awardsWorks" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.awardsName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <DatePicker
                readonly
                v-model="item.obtainTime"
                @on-change="item.obtainTime = $event"
                type="date"
                placeholder="选择时间"
                style="width: 200px"
              ></DatePicker>
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="item.authorizationDepartment"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 主要设计成果 -->
        <Row>
          <Col span="24">主要设计成果</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">项目名称</span>
            </Col>
            <Col class="m_col m_title" span="6">客户企业</Col>
            <Col class="m_col m_title" span="6">完成交付时间</Col>
            <Col class="m_col m_title" span="6">设计成果产业化及效果</Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .designResultList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="6">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input readonly v-model="item.projectName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <Input readonly v-model="item.clientCompany" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
              <DatePicker
                readonly
                v-model="item.finishDeliveryTime"
                @on-change="item.finishDeliveryTime = $event"
                type="date"
                placeholder="选择时间"
                style="width: 200px"
              ></DatePicker>
            </Col>
            <Col class="m_col" span="6">
              <Input
                readonly
                v-model="item.industrializationResult"
                class="unit_input"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
              />
            </Col>
          </Row>
        </div>
        <!-- 主要硬件设施 -->
        <Row>
          <Col span="24">主要硬件设施</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="5">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">仪器设备名称</span>
            </Col>
            <Col class="m_col m_title" span="4">台（套）数</Col>
            <Col class="m_col m_title" span="5">价值（万元）</Col>
            <Col class="m_col m_title" span="5">设备完好率（%）</Col>
            <Col class="m_col m_title" span="5">使用情况</Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .hardwareFacilityList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="5">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input readonly v-model="item.apparatusName" class="unit_input" />
            </Col>
            <Col class="m_col" span="4">
              <Input readonly v-model="item.numberOfUnits" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input
                readonly
                v-model="item.facilityIntactRate"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                readonly
                v-model="item.useInfo"
                class="unit_input"
              />
            </Col>
          </Row>
        </div>
        <!-- 主要软件 -->
        <Row>
          <Col span="24">主要软件设施</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="5">
              <Icon class="icon" type="ios-add-circle-outline" />
              <span class="text_col">软件名称</span>
            </Col>
            <Col class="m_col m_title" span="4">数量（套）</Col>
            <Col class="m_col m_title" span="5">价值（万元）</Col>
            <Col class="m_col m_title" span="5">使用情况</Col>
            <Col class="m_col m_title" span="5">备注</Col>
          </Row>
          <Row
            type="flex"
            v-for="(item, index) in formValidateThree.resourceAndPerfInfo
              .softwareList"
            :key="index"
            class="m_row"
          >
            <Col class="m_col icon_col" span="5">
              <Icon class="icon" type="ios-remove-circle-outline" />
              <Input readonly v-model="item.softwareName" class="unit_input" />
            </Col>
            <Col class="m_col" span="4">
              <Input readonly v-model="item.quantity" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
              <Input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4 }"
                readonly
                v-model="item.useInfo"
                class="unit_input"
              />
            </Col>
            <Col class="m_col" span="5">
              <Input readonly v-model="item.remark" class="unit_input" />
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面四 -->
    <div class="statusPlanning GL_declareForm Gl_input">
      <Crumbs :title="'现状及规划 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateFour"
        :model="formValidateFour"
        :label-width="0"
      >
        <!-- 设计成果获奖情况 -->
        <Row>
          <Col span="24">设计成果获奖情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24"
              >企业运营等有关情况</Col
            >
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidateFour.statusQuoPlan.operationInfo"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="重点是企业现在的组织体系、运营模式、产学研合作及专业人员培训及设计创新社会化服务等有关情况(800字以内)"
              ></Input>
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24"
              >企业参与国家、本市重要设计类活动情况</Col
            >
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidateFour.statusQuoPlan.activitySituation"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="(800字以内)"
              ></Input>
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="text-align: center" span="24"
              >今后两年目标与规划情况</Col
            >
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
              <Input
                readonly
                v-model="formValidateFour.statusQuoPlan.targetAndPlan"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 8 }"
                placeholder="重点是企业今后两年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、人才队伍建设、履行设计创新公共服务平台职责等规划和措施等情况(800字以内)"
              ></Input>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面五 -->
    <div class="member GL_declareForm Gl_input">
      <Form
        class="form"
        ref="formValidateFive"
        :model="formValidateFive"
        :label-width="0"
      >
        <!-- 工业设计团队带头人及主要成员情况 -->
        <!-- 基本情况 -->
        <div
          class="forItem"
          :key="index"
          v-for="(item, index) in formValidateFive.foregoerMemberList"
        >
          <Crumbs :title="'设计创新团队带头人及主要成员情况 '"></Crumbs>
          <Row>
            <Col span="24">基本情况</Col>
          </Row>
          <div class="iconBox">
            <Icon class="icon" type="ios-add-circle-outline" />
            <Icon class="icon" type="ios-remove-circle-outline" />
          </div>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">姓名</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.peopleName" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>性别</span>
              </Col>
              <Col class="m_col" span="8">
                <!-- <Input v-model="item.gender" class="unit_input" /> -->
                <RadioGroup size="large" v-model="item.gender">
                  <Radio disabled label="2">男</Radio>
                  <Radio disabled label="1">女</Radio>
                </RadioGroup>
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">出生年月</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.dateOfBirth" class="unit_input" />
                <!-- <DatePicker
                  readonly
                  v-model="item.dateOfBirth"
                  @on-change="item.dateOfBirth = $event"
                  type="date"
                  placeholder="选择时间"
                ></DatePicker> -->
              </Col>
              <Col class="m_col m_title" span="4">
                <span>国籍</span>
              </Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.nationality" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">公司职务</Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.companyPost" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
                <span>手机号码</span>
              </Col>
              <Col class="m_col" span="8">
                <Input readonly v-model="item.phone" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 职称学历 -->
          <Row>
            <Col span="24">职称学历</Col>
          </Row>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">专业职称</Col>
              <Col class="m_col" span="20">
                <Input readonly v-model="item.specialty" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">
                学历、学位
                <br />（毕业院校及专业）
              </Col>
              <Col class="m_col" span="20">
                <Input readonly v-model="item.education" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 工作经历及成绩 -->
          <div class="m_item">
            <Row class="m_row">
              <Col style="height: 120px" class="m_col m_title" span="4"
                >工作经历及成绩</Col
              >
              <Col style="height: 120px" class="m_col" span="20">
                <Input
                  readonly
                  v-model="item.workExperience"
                  type="textarea"
                  :autosize="{ minRows: 5, maxRows: 5 }"
                  placeholder="（从事设计创新领域的主要工作经历和取得的成绩）"
                ></Input>
              </Col>
            </Row>
          </div>
        </div>
        <!-- 工业设计团队带头人及主要成员情况 -->
      </Form>
    </div>
    <!-- 页面六 -->
    <div class="attachmentUpload GL_declareForm industryUpload">
      <Crumbs :title="'附件上传 '"></Crumbs>
      <Form
        class="form"
        ref="formValidateSix"
        :model="formValidateSix"
        :label-width="0"
      >
        <div class="m_item">
          <Row
            :key="item.sfId"
            v-for="item in formValidateSix.programFiles"
            type="flex"
            class="m_row"
          >
            <Col style="padding:8px" class="m_col m_title" span="8">
              <!-- <div class="text_box"> -->
              <span class="spantext">{{ item.declareMaterialName }}</span>
              <!-- </div> -->
            </Col>
            <Col class="s_col" span="16">
              <template v-for="(item2, index) in item.programFilesList">
                <div class="picText" :key="index">
                  <p>{{ item2.fileName }}</p>
                  <div
                    @click="preview(item2.filePath)"
                    class="demo-upload-list-cover"
                  >
                    <Icon type="ios-eye" style="color:#3385FF" size="30"></Icon>
                  </div>
                </div>
              </template>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <div style="text-align: center; ">
      <button @click="goBack" class="GL_cancel">返回</button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
export default {
  components: {
    Crumbs
  },
  name: "",
  props: {},
  data() {
    return {
      CheckboxArray: [],
      childrenList: [],
      // 区域列表
      areaList: [],
      forEachFiles: {},
      programId: null,
      specialFiles: [],
      //   页面一
      formValidate: {
        programId: null,
        pageStatus: 1,
        declareType: 3002,
        industrialDesignEnterprise: {
          programId: null
          // companyName: null,
          // companyAddress: null,
          // manageAddress: null, //企业经营地址
          // thatTime: null, //获得区级工业设计中心认定时间
          // establishedTime: null, //成立时间
          // registeredCapital: null, //注册资本
          // propertyTotal: null, //资产总额
          // employeeCount: null, //职工人数
          // propertyOfOwnership: null, //"所有制性质",
          // creditRating: null, //信用等级
          // companyIsBelong: null, //企业是否属于(1:上市企业 2：高新技术企业)
          // rangeOfServices: null,
          // principalName: null,
          // principalPost: null,
          // principalPhone: null,
          // contactsName: null,
          // contactsPost: null,
          // contactsPhone: null,
          // employeesCount: null, //工业设计从业人数
          // degreeOf: null, //其中：本科及以上学历人员数（含具有技师及以上职业资格人员、高级专业技术职务人员）和占比
          // degreeOfCount: null,
          // specialtyCount: null, //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数
          // specialtyProportion: null //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数及占⽐
        },
        industrialEiList: [
          //经济指标、工业设计成果
          {
            companyIncome: null, //公司营业收入
            industrialDesignDuty: null, //其中：工业设计服务收入及占比
            profitAndTaxTotal: null, //利税总额
            profitTotal: null, //利润总额
            projectFinishCount: null, //工业设计项目完成数
            epibolyProjectCount: null, //承担服务外包项目数
            foreignProjectCount: null, //其中:承担国外项目数
            patentLicensingCount: null, //专利授权数
            inventPatentCount: null, //其中:发明专利数
            copyrightAuthorization: null, //版权授权数
            year: 2017 //年份
          },
          {
            companyIncome: null,
            industrialDesignDuty: null,
            profitAndTaxTotal: null,
            profitTotal: null,
            projectFinishCount: null,
            epibolyProjectCount: null,
            foreignProjectCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            year: 2018
          },
          {
            companyIncome: null,
            industrialDesignDuty: null,
            profitAndTaxTotal: null,
            profitTotal: null,
            projectFinishCount: null,
            epibolyProjectCount: null,
            foreignProjectCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            year: 2022
          }
        ]
      },
      //   页面三
      formValidateThree: {
        programId: null,
        pageStatus: 3,
        declareType: "3002",
        resourceAndPerfInfo: {
          awardsInfoList: [
            {
              awardsWorks: null,
              awardsName: null,
              obtainTime: null,
              authorizationDepartment: null,
              programType: 2
            }
          ],
          designResultList: [
            {
              projectName: null,
              clientCompany: null,
              finishDeliveryTime: null,
              industrializationResult: null,
              programType: 2
            }
          ],
          hardwareFacilityList: [
            {
              apparatusName: null,
              numberOfUnits: null,
              cost: null,
              facilityIntactRate: null,
              useInfo: null,
              programType: 2
            }
          ],
          softwareList: [
            {
              softwareName: null,
              quantity: null,
              cost: null,
              useInfo: null,
              remark: null,
              programType: 2
            }
          ]
        }
      },
      //   页面四
      formValidateFour: {
        programId: null,
        pageStatus: 4,
        declareType: 3002,
        statusQuoPlan: {
          programId: null,
          operationInfo: null,
          targetAndPlan: null,
          programType: 1
        }
      },
      //   页面五
      formValidateFive: {
        programId: null,
        pageStatus: 5,
        declareType: 3002,
        foregoerMemberList: [
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 2
          },
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 2
          }
        ]
      },
      //   页面六
      formValidateSix: {
        programId: null,
        pageStatus: 6,
        declareType: 3002,
        programFiles: []
      }
    };
  },
  created() {
    // 获取区域列表
    this.getAreaList();
    this.pageOne();
    this.pageThree();
    this.pageFour();
    this.pageFive();
    this.pageSix();
    this.initAttachment();
  },
  watch: {},
  computed: {},

  mounted() {},
  methods: {
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
    },
    /*初始化数据
     *method queryPage
     */
    async pageOne() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 1,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidate.industrialDesignEnterprise =
            res.result.result.IndustrialDesignEnterpriseInfo;
          if (
            this.formValidate.industrialDesignEnterprise.companyName == null
          ) {
            this.formValidate.industrialDesignEnterprise.companyName =
              localStorage.companyName;
          }
          if (
            res.result.result.IndustrialDesignEnterpriseInfo.industrialEiList
              .length == 3
          ) {
            this.formValidate.industrialEiList =
              res.result.result.IndustrialDesignEnterpriseInfo.industrialEiList;
          }

          this.formValidate.industrialDesignEnterprise.unitRegistrationTypeParentId =
            this.formValidate.industrialDesignEnterprise
              .unitRegistrationTypeParentId - 0;
          this.formValidate.industrialDesignEnterprise.propertyOfOwnership =
            this.formValidate.industrialDesignEnterprise.propertyOfOwnership -
            0;
          this.formValidate.companyType = res.result.result.companyType;
          let index = this.formValidate.companyType.findIndex(
            item =>
              item.dictId ==
              this.formValidate.industrialDesignEnterprise
                .unitRegistrationTypeParentId
          );
          if (index == -1) {
            this.childrenList = [];
          } else {
            this.childrenList = this.formValidate.companyType[
              index
            ].sysDictDataList;
          }
          // 多选框
          if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 1
          ) {
            this.CheckboxArray = ["上市企业"];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 2
          ) {
            this.CheckboxArray = ["高新技术企业"];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 4
          ) {
            this.CheckboxArray = ["上市企业", "高新技术企业"];
          }
        }
      }
    },
    async pageThree() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 3,
          declareType: 3002
        }
      );
      if (res.messageCode == 10000 && res.result.result != null) {
        this.formValidateThree.resourceAndPerfInfo = res.result.result;
      }
    },
    async pageFour() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 4,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000 && res.result.result != null) {
        if (res.result.result != null) {
          this.formValidateFour.statusQuoPlan = res.result.result;
        }
      }
    },
    async pageFive() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 5,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null && res.result.result.length > 0) {
          this.formValidateFive.foregoerMemberList = res.result.result;
        }
      }
    },
    async pageSix() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 6,
          declareType: 3002,
          specialId: this.$route.query.specialId
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidateSix.programFiles = res.result.result;
        }
      }
    },

    /*初始化专项附件
     *method  initAttachment
     **/
    async initAttachment() {
      var res = await this.$http.post("shsj_declare/sysDict/getSpecialFile", {
        specialId: this.$route.query.specialId,
        declareType: 3002
      });
      if (res.messageCode == 10000) {
        this.specialFiles = res.result.specialFiles;
      }
    },
    /** 返回
     * method goBack
     * */
    goBack() {
      this.$router.go(-1);
    },
    /*method
     **点击预览
     */

    preview(path) {
      window.open(path);
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.industryUpload {
  .spantext {
    max-width: 100%;
    display: inline-block;
    overflow-wrap: break-word; /*  css3属性，当内容超过指定容器的边界时是否断行  */
  }
  .m_item {
    .s_col {
      border-right: 1px solid #e8ecf5;
      border-bottom: 1px solid #e8ecf5;
      .ivu-btn {
        margin: 5px;
        border-radius: 20px;
        padding: 10px 50px;
      }
      .picText {
        padding: 10px;
        display: inline-block;
      }
    }
  }
}
.member {
  .forItem {
    position: relative;
    .iconBox {
      // width: 50px;
      position: absolute;
      left: 310px;
      top: 9px;
      // right: 0;
      z-index: 888;
      .icon {
        padding: 0px 5px;
        color: #3385ff;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>
